@import "Themes/constants.scss";

.root {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	gap: 32px;

	.tetu-ad {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 60px 128px;
		grid-column: 1/3;
		width: 100%;
		background: url("../../../../assets/images/tetu-ad-background.png");
		background-size: cover;

		@media (max-width: $screen-m) {
			padding: 130px 90px;
			flex-direction: column-reverse;

			.tetu-magazine {
				margin-bottom: 56px;
			}
		}

		@media (max-width: $screen-s) {
			padding: 60px 32px;
		}

		.tetu-infos {
			max-width: 580px;

			@media screen and (max-width: $screen-s) {
				max-width: 100%;
			}

			.tetu-title {
				font-size: 80px;
				font-weight: 800;
				color: #090909;

				@media screen and (max-width: $screen-s) {
					font-size: 48px;
				}
			}

			.tetu-description {
				margin-top: 32px;
				font-weight: 700;
				font-size: 28px;
				color: #1b1f23;

				@media (max-width: $screen-s) {
					font-size: 16px;
				}
			}
		}

		.tetu-magazine {
			@media screen and (max-width: $screen-s) {
				height: 290px;
			}
		}
	}
}
