@import "Themes/constants.scss";

.root {
	transition: all 200ms $custom-easing;

	.dropdown-header-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		.dropdown-label {
			margin: 24px;
			font-size: 28px;
			font-weight: 700;
			color: #1b1f23;

			@media screen and (max-width: $screen-s) {
				font-size: 20px;
			}
		}

		.dropdown-arrow {
			margin-left: 32px;
			min-height: 36px;
			min-width: 36px;
			transform: scaleY(-1);
			transition: transform 200ms $custom-easing;

			path {
				fill: var(--color-generic-black);
			}
		}
	}

	.dropdown-content-container {
		overflow: hidden;
		opacity: 0;
	}

	&[data-animate="true"] {
		.dropdown-content-container {
			transition: height 350ms $custom-easing, opacity 350ms $custom-easing;
		}
	}

	&[data-open="true"] {
		.dropdown-arrow {
			transform: scaleY(1);
		}

		.dropdown-content-container {
			opacity: 1;
		}
	}
}
