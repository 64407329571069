@import "Themes/constants.scss";

.root {
	max-width: 1088px;
	width: 100%;
	margin: 0 auto;

	.text {
		font-family: var(--font-secondary);
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		color: var(--color-generic-black);
	}

	.headline {
		text-align: center;
		font-style: italic;
	}

	.bold {
		font-weight: 800;
	}

	.headline-bold {
		font-weight: 700;
		font-size: 22px;
		line-height: 29px;
		text-align: center;
		color: var(--color-generic-black);
	}

	.spacer {
		margin-top: 32px;
	}

	.flex {
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;

		@media screen and (max-width: $screen-m) {
			flex-direction: column;
		}

		.left-container,
		.right-container {
			text-align: justify;
			max-width: 514px;

			@media screen and (max-width: $screen-m) {
				max-width: unset;
			}
		}

		.right-container {
			margin-left: 22px;

			@media screen and (max-width: $screen-m) {
				margin-left: 0;
				margin-top: 32px;
			}
		}
	}
}
