@import "Themes/constants.scss";

.root {
	display: flex;
	flex-direction: column;
	padding: 80px 60px;
	width: 100%;
	background-color: var(--color-orange-tetu);

	@media (max-width: $screen-m) {
		grid-column: 1/3;
	}

	@media (max-width: $screen-s) {
		padding: 32px 32px 0;
	}

	.set-title {
		margin-bottom: 48px;
		font-weight: 800;
		font-size: 60px;
		letter-spacing: -2px;
		color: #090909;

		@media screen and (max-width: $screen-l) {
			font-size: 50px;
		}

		@media screen and (max-width: $screen-s) {
			font-size: 28px;
		}
	}

	.set-infos {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		flex: 1;

		.set-description {
			display: flex;
			flex-direction: column;
			font-weight: 700;
			font-size: 26px;
			line-height: 45px;
			color: #090909;

			@media (max-width: $screen-l) {
				font-size: 24px;
			}

			@media (max-width: $screen-s) {
				font-size: 16px;
				line-height: 32px;
			}
		}

		.button {
			display: flex;
			align-self: flex-end;
			justify-content: space-between;
			align-items: center;
			height: max-content;
			margin-top: 48px;
			width: 295px;
			padding: 29px 24px;
			font-weight: 600;
			font-size: 20px;
			color: var(--color-generic-white);
			background-color: #090909;
			cursor: pointer;
			border: none;

			@media (max-width: $screen-s) {
				position: relative;
				left: 32px;
				width: calc(100% + 64px);
				justify-content: center;
			}

			.tezos-icon {
				path {
					fill: var(--color-generic-white);
				}
			}
		}

		.center {
			justify-content: center;
		}
	}
}
