@import "Themes/constants.scss";

.root {
	.title {
		margin-top: calc(40px + 134px);
		font-family: var(--font-secondary);
		font-weight: 400;
		font-size: 96px;
		text-align: center;

		@media (max-width: $screen-s) {
			font-size: 42px;
		}
	}

	.coming-soon-description {
		max-width: 911px;
		margin: 32px auto 104px;
		font-family: var(--font-secondary);
		font-weight: 600;
		font-size: 18px;
		color: var(--color-generic-black);
	}

	.description-container {
		margin-top: 32px;
		display: flex;
		justify-content: center;

		:first-child {
			margin-right: 40px;

			@media (max-width: $screen-xs) {
				margin-right: 0;
				margin-bottom: 16px;
			}
		}

		@media (max-width: $screen-xs) {
			flex-direction: column;
		}

		.description {
			max-width: 435px;
			font-weight: 600;
			font-size: 16px;
			text-align: justify;
			color: var(--color-generic-black);

			@media (max-width: $screen-xs) {
				max-width: unset;
			}
		}
	}

	.scroll-to-rewards {
		position: relative;
		left: -16px;
		padding: 0 16px;
		margin: 50px 0;
		width: calc(100% + 32px);
		height: 58px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: var(--color-generic-black);
		cursor: pointer;

		.text {
			font-weight: 700;
			font-size: 16px;
			text-transform: uppercase;
			color: var(--color-generic-white);
		}

		.arrow-down {
			path {
				fill: var(--color-generic-white);
			}
		}
	}

	.subtitle {
		position: relative;
		margin-top: 104px;
		margin-bottom: 32px;
		font-family: var(--font-secondary);
		font-weight: 400;
		font-size: 80px;
		text-transform: uppercase;
		color: var(--color-generic-black);

		@media (max-width: $screen-s) {
			font-size: 38px;
		}

		@media (max-width: $screen-xs) {
			margin-top: 0;
			font-size: 30px;
		}
	}

	.subtitle-container {
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		.ending-date {
			position: relative;
			top: 100px;

			@media (max-width: $screen-s) {
				top: 36px;
			}
		}
	}
}
