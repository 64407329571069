@import "Themes/constants.scss";

body {
	--color-generic-white: #{$color-generic-white};
	--color-generic-black: #{$color-generic-black};

	--color-pink-tetu: #{$pink-tetu};
	--color-orange-tetu: #{$orange-tetu};
	--color-yellow-tetu: #{$yellow-tetu};
}

[theme-mode] {
}

[theme-mode="dark"] {
}
