@import "Themes/constants.scss";

.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	@media (max-width: 600px) {
		flex-direction: column;
	}

	.nft-infos {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-right: 16px;

		@media (max-width: 600px) {
			padding: 26px 30px;
			margin-right: 0;
			border-top: 1px solid #efede4;
			width: calc(100% + 64px);
		}

		.nft-month {
			font-weight: 800;
			font-size: 28px;
			color: #000000;
			text-transform: uppercase;

			@media (max-width: $screen-s) {
				font-size: 16px;
			}
		}
	}

	.button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: max-content;
		min-width: 295px;
		height: 73px;
		padding: 14px 24px;
		font-weight: 600;
		font-size: 20px;
		color: var(--color-generic-white);
		background-color: #090909;
		cursor: pointer;
		border: none;

		@media (max-width: 600px) {
			width: calc(100% + 64px);
			padding: 24px;
		}

		@media (max-width: $screen-xs) {
			width: calc(100% + 48px);
		}

		.tezos-icon {
			path {
				fill: var(--color-generic-white);
			}
		}

		.loader {
			height: 44px;
		}
	}

	.center {
		justify-content: center;
	}
}

.nb-asset-minted {
	margin-bottom: 24px;
	text-align: right;
	font-size: 16px;
	font-weight: 400;

	@media (max-width: $screen-s) {
		font-size: 14px;
	}

	.link {
		text-decoration: underline;
	}
}
