@import "Themes/constants.scss";

.root {
	.title {
		font-weight: 700;
		font-size: 28px;
		text-transform: uppercase;
		text-align: center;
		color: #090909;
	}

	.mint-type-container {
		margin: 40px 0;
		padding: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: $screen-s) {
			flex-direction: column;
			padding: 24px 16px;
		}

		.mint-type-infos {
			margin-right: 26px;

			@media (max-width: $screen-s) {
				margin-right: 0;
				margin-bottom: 48px;
			}

			.mint-type-title {
				margin-bottom: 32px;
				font-weight: 700;
				font-size: 28px;
				color: #090909;
			}

			.mint-type-description {
				font-weight: 700;
				font-size: 16px;
				text-transform: uppercase;
				color: #090909;

				&:not(:last-child) {
					margin-bottom: 16px;
				}
			}
		}
	}

	.yearly-set-background {
		background-color: var(--color-pink-tetu);
	}

	.semester-set-background {
		background-color: var(--color-orange-tetu);
	}

	.season-set-background {
		background-color: var(--color-yellow-tetu);
	}
}
