@import "Themes/constants.scss";

.root {
	.image {
		width: 100%;
	}

	.subtitle {
		margin: 22px 0;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 36px;
		color: var(--color-generic-black);

		@media (max-width: $screen-xs) {
			font-size: 24px;
		}
	}

	.text {
		margin: 22px 0;
		font-weight: 600;
		font-size: 18px;
		font-family: var(--font-secondary);
		color: var(--color-generic-black);

		@media (max-width: $screen-xs) {
			font-size: 16px;
		}
	}

	.no-margin {
		margin: 0;
	}
}
