$screen-xl: 2559px;
$screen-l: 1440px;
$screen-m: 1023px;
$screen-s: 767px;
$screen-xs: 424px;

$custom-easing: cubic-bezier(0.645, 0.045, 0.355, 1);

$color-generic-white: #ffffff;
$color-generic-black: #000000;

$pink-tetu: #fdcaca;
$orange-tetu: #fde9ca;
$yellow-tetu: #fdfbca;
