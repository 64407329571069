@import "Themes/constants.scss";

.root {
	position: relative;

	.newsletter {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		position: absolute;
		z-index: 2;
		width: 100%;
		height: 930px;

		@media screen and (max-width: $screen-s) {
			align-items: unset;
		}

		.newsletter-container {
			max-width: 800px;
			width: 100%;
			text-align: center;

			.title {
				font-family: var(--font-secondary);
				font-weight: 400;
				font-size: 80px;
				color: var(--color-generic-black);

				@media screen and (max-width: $screen-s) {
					font-size: 30px;
				}
			}

			.description {
				margin: 32px 0;
				font-weight: 700;
				font-size: 28px;
				line-height: 45px;
				color: #090909;

				@media screen and (max-width: $screen-s) {
					font-size: 16px;
					line-height: 32px;
				}
			}

			.form {
				display: flex;
				justify-content: center;

				@media screen and (max-width: $screen-s) {
					flex-direction: column;
				}

				.input-container {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					padding: 18px 24px;
					min-width: 452px;
					min-height: 73px;
					background: var(--color-generic-white);

					@media screen and (max-width: $screen-s) {
						min-width: 100%;
					}

					.label {
						font-weight: 400;
						font-size: 12px;
						text-transform: uppercase;
						color: #78766d;
					}

					.input {
						width: 100%;
						font-weight: 600;
						font-size: 16px;
						color: #090909;
						border: none;

						&:focus {
							outline: none;
						}
					}
				}

				.button {
					min-width: 227px;
					height: 73px;
					background: #090909;
					border: none;
					font-weight: 600;
					font-size: 20px;
					color: var(--color-generic-white);
					cursor: pointer;
				}
			}
		}
	}

	.children-container {
		height: 1225px;
		overflow: hidden;
		filter: blur(25px);
		// Had to add this line to fix the blur issue on Safari
		transform: translate3d(0, 0, 0);
	}
}
