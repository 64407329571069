@import "Themes/constants.scss";

.root {
	max-width: 490px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.emoji {
		font-size: 80px;

		@media screen and (max-width: $screen-s) {
			font-size: 60px;
		}
	}

	.title {
		text-align: center;
		margin: 32px 0;
		font-weight: 700;
		font-size: 28px;
		color: #090909;

		@media screen and (max-width: $screen-s) {
			font-size: 20px;
		}
	}

	.description {
		text-align: center;
		font-weight: 700;
		font-size: 28px;
		line-height: 45px;
		color: var(--color-generic-black);

		@media screen and (max-width: $screen-s) {
			font-size: 20px;
			line-height: 30px;
		}
	}

	.button {
		margin-top: 60px;
		padding: 28px 36px;
		font-weight: 600;
		font-size: 20px;
		border: none;
		background-color: #090909;
		color: var(--color-generic-white);
		cursor: pointer;
	}
}
