@import "Themes/constants.scss";

.root {
	margin-top: 104px;

	.title {
		text-transform: uppercase;
		text-align: center;
		font-family: var(--font-secondary);
		font-weight: 400;
		font-size: 80px;
		color: var(--color-generic-black);

		@media screen and (max-width: $screen-s) {
			font-size: 30px;
		}
	}

	.social-container {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 40px;
		margin-top: 64px;
		margin-bottom: 104px;

		@media screen and (max-width: $screen-m) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
		}

		@media screen and (max-width: $screen-s) {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr 1fr 1fr;
		}

		.social-box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 270px;

			&:nth-child(1) {
				background-color: var(--color-pink-tetu);
			}

			&:nth-child(2) {
				background-color: var(--color-orange-tetu);
			}

			&:nth-child(3) {
				background-color: var(--color-yellow-tetu);
			}

			&:nth-child(4) {
				background-color: #efede4;
			}

			.image {
				width: 132px;
				border-radius: 50%;
			}

			.social-icons {
				margin-top: 40px;

				.link {
					&:not(:last-child) {
						margin-right: 24px;
					}
				}
			}
		}
	}
}
