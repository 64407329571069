@import "Themes/constants.scss";

.root {
	transition: all 200ms $custom-easing;

	.dropdown-header-container {
		text-align: center;
		text-decoration: underline;
		cursor: pointer;
	}

	.dropdown-content-container {
		overflow: hidden;
		opacity: 0;
	}

	&[data-animate="true"] {
		.dropdown-content-container {
			transition: height 350ms $custom-easing, opacity 350ms $custom-easing;
		}
	}

	&[data-open="true"] {
		.dropdown-arrow {
			transform: scaleY(1);
		}

		.dropdown-content-container {
			opacity: 1;
		}
	}
}
