@import "Themes/constants.scss";

.root {
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #090909;
		opacity: 0.8;
	}

	.container {
		position: relative;
		padding: 40px 60px;
		max-width: 828px;
		width: 100%;
		max-height: 90vh;
		background: var(--color-generic-white);
		box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
		overflow: scroll;

		@media (max-width: $screen-s) {
			width: 90%;
			padding: 40px 16px;
		}
	}
}
