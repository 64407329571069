.emoji {
	text-align: center;
	font-size: 80px;
}

.title {
	margin: 32px 0;
	font-weight: 700;
	font-size: 28px;
	line-height: 34px;
	text-align: center;
	text-transform: uppercase;
	color: #090909;
}

.content {
	max-width: 546px;
	margin: 32px auto;
	font-weight: 700;
	font-size: 28px;
	line-height: 45px;
	text-align: center;
	color: #000000;

	.block {
		display: block;
	}
}

.button-container {
	text-align: center;

	.button {
		font-weight: 600;
		font-size: 20px;
		text-align: center;
		color: var(--color-generic-white);
		width: 182px;
		height: 73px;
		background: #090909;
		border: none;
		cursor: pointer;
	}
}
