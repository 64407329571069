.yearly-images-container {
	height: 267px;
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr;

	.image {
		width: 88px;
		height: 58px;
		object-fit: cover;
	}
}

.semester-images-container {
	width: 251px;
	height: 267px;
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;

	.image {
		width: 121px;
		height: 81px;
		object-fit: cover;
	}
}

.claim-rewards-images-container {
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;

	.image {
		width: 100%;
		height: 68px;
		object-fit: cover;
	}
}

.season-images-container {
	display: grid;
	gap: 8px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;

	.image {
		width: 121px;
		height: 81px;
		object-fit: cover;

		&:last-child {
			grid-column: 1 / 3;
			width: 250px;
		}
	}
}
