@import "Themes/constants.scss";

.root {
	display: inline-flex;
	gap: 8px;
	align-items: center;
	font-weight: 600;
	font-family: var(--font-primary);
	border: none;
	user-select: none;
	cursor: pointer;
	white-space: nowrap;
	transition: background-color 0.2s $custom-easing;

	&[variant="primary"] {
		padding: 29px 75px;
		font-size: 20px;
		color: var(--color-generic-white);
		background-color: var(--color-generic-black);

		&:disabled {
			pointer-events: none;
		}
	}

	&[variant="secondary"] {
		padding: 17px 40.5px;
		color: var(--color-generic-black);
		border: 1px solid #9a9991;
		border-radius: 50px;
		background-color: var(--color-generic-white);

		@media screen and (max-width: $screen-s) {
			padding: 8px 17px;
			font-size: 14px;
		}

		&:disabled {
			pointer-events: none;
		}
	}
}
