@import "Themes/constants.scss";

.root {
	margin-left: 40px;
	font-size: 26px;
	user-select: none;
	cursor: pointer;

	@media screen and (max-width: $screen-s) {
		margin-left: 20px;
	}
}
