@import "Themes/constants.scss";

.root {
	position: relative;
	width: 50%;

	&:nth-child(odd) {
		padding-right: 22px;

		@media screen and (max-width: $screen-s) {
			padding-right: 0;
		}
	}

	&:nth-child(even) {
		margin-top: 124px;
		padding-left: 22px;

		@media screen and (max-width: $screen-s) {
			margin-top: 0;
			padding-left: 0;
		}
	}

	@media (max-width: $screen-s) {
		width: 100%;
		margin-bottom: 36px;
	}

	.image-container {
		position: relative;
		display: block;
		width: 100%;
		max-height: 445px;

		.image {
			width: 100%;
			object-fit: cover;
		}
	}

	.floating-emoji {
		position: absolute;
		top: 100px;
		font-size: 60px;
	}

	.infos {
		margin-top: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		user-select: none;

		.name {
			font-weight: 700;
			font-size: 28px;
			color: var(--color-generic-black);

			@media screen and (max-width: $screen-m) {
				font-size: 22px;
			}

			@media screen and (max-width: $screen-s) {
				font-size: 16px;
			}

			.emoji {
				img {
					position: relative;
					top: 2px;
				}

				@media screen and (max-width: $screen-s) {
					img {
						width: 16px;
					}
				}
			}

			.model-name {
				font-weight: 600;
				font-size: 22px;

				@media screen and (max-width: $screen-s) {
					font-size: 12px;
				}
			}
		}

		.price {
			margin-left: 16px;
			white-space: nowrap;
			font-weight: 800;
			font-size: 22px;
			text-align: right;
			color: var(--color-generic-black);
		}
	}
}

.blur {
	filter: blur(25px);
	// Had to add this line to fix the blur issue on Safari
	transform: translate3d(0, 0, 0);
}
