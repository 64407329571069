@import "Themes/constants.scss";

.root {
	.gallery-section {
		padding: 32px 60px;
		max-width: var(--content-max-width);
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-direction: column;

		@media screen and (max-width: $screen-m) {
			padding: 24px 32px;
		}

		@media screen and (max-width: $screen-xs) {
			padding: 24px 16px;
		}
	}

	.buy-set-section {
		background-color: #f8f7f1;
	}
}
