@import "Themes/constants.scss";

.root {
	margin-bottom: 104px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 144px;
	width: 100%;

	@media screen and (max-width: $screen-m) {
		flex-direction: column;
	}

	.image-container {
		position: relative;

		@media screen and (max-width: $screen-xs) {
			width: 100%;

			.image {
				width: 100%;
				max-height: 233px;
				object-fit: cover;
				object-position: top;
			}
		}

		.image {
			@media screen and (max-width: $screen-m) {
				width: 100%;
				object-fit: cover;
			}
		}

		.emoji {
			position: absolute;
			top: -40px;
			right: -12px;
			transform: rotate(14deg);
			font-size: 80px;
			user-select: none;
		}
	}

	.text-container {
		margin-left: 86px;

		@media screen and (max-width: $screen-m) {
			margin-left: 0;
			margin-top: 32px;
		}

		.title {
			margin-bottom: 32px;
			font-family: vat(--font-secondary);
			font-weight: 400;
			font-size: 80px;
			color: #090909;

			@media screen and (max-width: $screen-l) {
				font-size: 72px;
			}

			@media screen and (max-width: $screen-m) {
				font-size: 30px;
			}
		}

		.description {
			font-weight: 700;
			font-size: 16px;
			line-height: 32px;
			color: #1b1f23;

			@media screen and (max-width: $screen-l) {
				line-height: 28px;
				font-size: 14px;
			}

			@media screen and (max-width: $screen-m) {
				text-align: justify;
			}
		}
	}
}
