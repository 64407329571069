@import "Themes/constants.scss";

.root {
	position: fixed;
	left: 0;
	top: 0;
	height: 134px;
	width: 100%;
	z-index: 3;
	background-color: var(--color-generic-white);

	.content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: var(--template-margin);
		max-width: var(--content-max-width);
		width: 100%;
		height: 100%;
		padding: 0 60px;

		@media screen and (max-width: $screen-s) {
			padding: 0 32px;
		}

		@media screen and (max-width: $screen-xs) {
			padding: 0 16px;
		}

		.title {
			color: var(--color-generic-black);
			font-weight: 900;
			font-size: 32px;
			white-space: nowrap;

			@media screen and (max-width: $screen-s) {
				font-size: 22px;
			}

			@media screen and (max-width: $screen-xs) {
				font-size: 18px;
			}

			.slash {
				font-weight: 100;
			}
		}

		.divider {
			margin: 0 24px;
			height: 1px;
			width: 100%;
			background-color: #cfcfcf;

			@media screen and (max-width: $screen-s) {
				height: 0;
				margin: 0;
			}
		}
	}
}
