@import "Themes/constants.scss";

.root {
	max-width: var(--content-max-width);
	margin: var(--template-margin);
	padding: 0 60px 40px;

	@media screen and (max-width: $screen-m) {
		padding: 0 32px 20px;
	}

	@media screen and (max-width: $screen-xs) {
		padding: 0 16px 20px;
	}
}

.no-padding {
	padding: 0;
}

.no-max-width {
	max-width: unset;
}
