.root {
	min-width: 240px;
	max-width: 240px;
	cursor: pointer;

	.set {
		display: flex;
		height: 254px;
		background-color: var(--color-orange-tetu);

		.text {
			width: 90%;
			margin: 0 auto;
			margin-bottom: 28px;
			align-self: flex-end;
			text-align: center;
			font-weight: 400;
			font-size: 16px;
			color: #090909;

			.bold {
				font-weight: 700;
			}
		}

		.fr {
			margin-bottom: 12px;
		}

		.images {
			position: relative;

			.image {
				position: absolute;
				width: 75px;
				height: 75px;
				object-fit: cover;

				&:nth-child(1) {
					top: 32px;
					left: 71px;
				}

				&:nth-child(2) {
					top: 48px;
					left: 80px;
				}

				&:nth-child(3) {
					top: 65px;
					left: 94px;
				}
			}

			.blur {
				filter: blur(15px);
			}
		}
	}

	.button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: max-content;
		width: 100%;
		padding: 24px;
		font-weight: 600;
		font-size: 20px;
		color: var(--color-generic-white);
		background-color: #090909;
		cursor: pointer;
		border: none;

		.tezos-icon {
			path {
				fill: var(--color-generic-white);
			}
		}
	}

	.center {
		justify-content: center;
	}
}
