@import "Themes/constants.scss";

.root {
	position: relative;

	.image {
		@media screen and (max-width: $screen-m) {
			max-height: 150px;
		}

		@media screen and (max-width: $screen-s) {
			max-height: 110px;
		}
	}

	.text-container {
		position: absolute;
		top: 48px;
		left: 32px;
		transform: rotate(-10.36deg);

		@media screen and (max-width: $screen-m) {
			top: 42px;
			left: 26px;
		}

		@media screen and (max-width: $screen-s) {
			top: 34px;
			left: 24px;
		}

		.text {
			text-align: center;
			font-weight: 900;
			font-size: 28px;
			font-family: var(--font-primary);
			color: var(--color-generic-white);

			@media screen and (max-width: $screen-m) {
				font-size: 24px;
			}

			@media screen and (max-width: $screen-s) {
				font-size: 16px;
			}
		}
	}
}
