@import "Themes/constants.scss";

.root {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: $screen-s) {
		flex-direction: column-reverse;
	}

	.button {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 14px 24px;
		min-width: 295px;
		height: 73px;
		font-weight: 600;
		font-size: 20px;
		color: var(--color-generic-white);
		background-color: #090909;
		white-space: nowrap;
		cursor: pointer;
		border: none;

		@media (max-width: $screen-s) {
			min-width: unset;
			width: 100%;
			justify-content: space-between;
			padding: 24px;
		}

		.price {
			.tezos-icon {
				path {
					fill: var(--color-generic-white);
				}
			}
		}

		.loader {
			height: 44px;
		}
	}

	.link {
		display: block;
		text-align: center;
		padding: 24px;
	}

	.button-container {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media screen and (max-width: $screen-s) {
			flex-direction: column;
			align-items: flex-end;
			width: 100%;
		}
	}

	.secondary {
		min-width: 182px;
		background-color: var(--color-generic-white);
		color: #090909;
		border: 2px solid #090909;
		justify-content: center;

		@media (max-width: $screen-s) {
			margin-top: 16px;
		}
	}
}
