@import "Themes/constants.scss";

.root {
	margin-bottom: 56px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	@media screen and (max-width: $screen-xs) {
		align-items: flex-start;
	}

	.nft-infos-container {
		.nft-title {
			font-weight: 700;
			font-size: 28px;
			color: #090909;

			@media (max-width: $screen-s) {
				font-size: 16px;
			}

			.emoji {
				@media screen and (max-width: $screen-s) {
					img {
						width: 16px;
					}
				}
			}
		}

		.nft-location {
			position: relative;
			left: 34px;
			font-weight: 400;
			font-size: 28px;
			color: #090909;

			@media (max-width: $screen-s) {
				font-size: 16px;
				left: 20px;
			}
		}
	}

	.cross-icon {
		cursor: pointer;

		path {
			stroke: var(--color-generic-black);
		}
	}

	.social-link {
		text-decoration: underline;
	}
}
