@import "Themes/constants.scss";

.root {
	margin: 0 auto;
	padding: 40px 60px;
	max-width: var(--content-max-width);
	width: 100%;

	@media (max-width: $screen-s) {
		padding: 40px 16px;
	}

	.title {
		font-weight: 400;
		font-size: 28px;
		color: #090909;

		@media (max-width: $screen-s) {
			font-size: 18px;
		}
	}

	.sets {
		margin-top: 40px;
		display: flex;
		overflow: scroll;

		&::-webkit-scrollbar {
			display: none;
		}

		> div {
			margin-right: 64px;
		}
	}
}
