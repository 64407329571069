@import "Themes/constants.scss";

.root {
	padding: 40px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media (max-width: $screen-s) {
		flex-direction: column;
	}
}
