@import "Themes/constants.scss";

.root {
	position: relative;
	width: 100%;
	margin-bottom: 56px;

	.arrow-container {
		display: flex;
		justify-content: space-between;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;

		@media (max-width: $screen-m) {
			position: relative;
			top: 0;
			transform: none;
		}

		.arrow {
			cursor: pointer;

			path {
				fill: var(--color-generic-black);
			}
		}
	}

	.image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		max-width: 871px;
		overflow: hidden;

		.image {
			width: 100%;
			height: 100%;
			object-fit: cover;
			user-select: none;
		}
	}
}
