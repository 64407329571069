@import "Themes/constants.scss";

.root {
	max-width: 800px;
	width: 100%;
	margin: 0 auto;

	.title {
		margin-bottom: 32px;
		text-align: center;
		font-family: var(--font-secondary);
		font-weight: 400;
		font-size: 80px;
		color: var(--color-generic-black);

		@media screen and (max-width: $screen-s) {
			font-size: 30px;
		}
	}

	.dropdown-text {
		margin: 24px;
		font-weight: 400;
		font-size: 15px;
		color: #1b1f23;

		.spacer {
			display: block;
			margin-top: 24px;
		}

		.link {
			text-decoration: underline;
		}

		.bold {
			font-weight: 800;
		}
	}
}
