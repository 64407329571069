@import "Themes/constants.scss";

.root {
	position: relative;
	padding: 32px;
	max-width: 767px;
	margin: 32px auto;
	background-color: var(--color-pink-tetu);

	.title {
		max-width: 615px;
		margin-bottom: 25px;
		font-weight: 800;
		font-size: 40px;
		line-height: 44px;
		letter-spacing: -2px;
		color: #090909;

		@media screen and (max-width: $screen-m) {
			max-width: 436px;
		}
	}

	.content {
		display: flex;

		@media screen and (max-width: $screen-s) {
			flex-direction: column;
		}

		.right-part,
		.left-part {
			flex: 1;

			@media screen and (max-width: $screen-s) {
				width: 100%;
			}
		}

		.left-part {
			width: 310px;
			margin-right: 24px;

			@media screen and (max-width: $screen-s) {
				width: 100%;
				margin-right: 0;
			}
		}

		.text {
			margin: 8px 0;
			font-weight: 400;
			font-size: 18px;
			line-height: 33px;
			color: #090909;
		}

		.block {
			display: block;
		}

		.bold {
			font-weight: 600;
		}
	}

	.button-container {
		margin-top: 32px;
		text-align: center;

		.button {
			padding: 18px 42px;
			font-weight: 600;
			font-size: 15px;
			line-height: 20px;
			color: #090909;
			border: 1px solid #9a9991;
			border-radius: 50px;
			background-color: var(--color-generic-white);
			transition: border 0.3s;
			cursor: pointer;

			&:hover {
				border: 1px solid var(--color-generic-white);
			}
		}
	}

	.drop-ended {
		position: absolute;
		top: -30px;
		right: -92px;
		width: 150px;
		height: 150px;

		@media screen and (max-width: $screen-m) {
			right: 10px;
			top: -18px;
		}
	}

	.mosaique-container {
		margin-bottom: 32px;
	}
}
