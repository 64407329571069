@import "Themes/constants.scss";

.root {
	margin: calc(134px + 40px) auto 0;
	max-width: 1088px;

	.title {
		text-align: center;
		text-transform: uppercase;
		font-weight: 400;
		font-size: 96px;
		font-family: var(--font-secondary);
		color: #090909;

		@media (max-width: $screen-s) {
			font-size: 62px;
		}

		@media (max-width: $screen-xs) {
			font-size: 52px;
		}
	}

	.text {
		margin: 22px 0;
		font-weight: 600;
		font-size: 18px;
		font-family: var(--font-secondary);
		color: var(--color-generic-black);

		@media (max-width: $screen-xs) {
			font-size: 16px;
		}
	}

	.link {
		text-decoration: underline;
	}

	.center {
		text-align: center;
	}

	.divider {
		height: 200px;
	}
}
