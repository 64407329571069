.root {
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;

	.margin-right {
		margin-right: 8px;
	}

	.text {
		font-weight: 600;
		font-size: 12px;
		font-family: var(--font-secondary);
		color: #0c0b0b;
	}
}
